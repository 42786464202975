import React from 'react';
import PrivateRoute from 'shared/auth/private-route';
import SiloOrderForm from './SiloOrderForm';
import SiloOrders from './siloOrders';

export const readSiloOrdersRight = 'READ:Order';

export const siloOrdersTo = '/orders';
export const newSiloOrdersTo = '/orders/new';

const Routes = () => (
  <div>
    <PrivateRoute
      path={newSiloOrdersTo}
      exact
      component={SiloOrderForm}
      hasRight={readSiloOrdersRight}
    />
    <PrivateRoute path={siloOrdersTo} exact component={SiloOrders} hasRight={readSiloOrdersRight} />
  </div>
);

export default Routes;
