import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios, { AxiosResponse } from 'axios';
import { getEnvApiUrl } from 'config/env';
import { AppThunk } from 'config/store';
import { IDevice } from 'shared/model/device.model';
import { IGroup, IGroupRole } from 'shared/model/group.model';
import { getRequestErrorMessage } from 'shared/utils/axios-utils';
import { errorNotification } from './notifierSlice';
import { findIndex } from 'lodash';
import { updateDevicesFromGroup } from './devicesSlice';

interface IGroupACLResponse {
  count: string;
  results: IGroupRole[];
}

const initialState = {
  loading: false,
  errorMessage: '',
  groups: [] as IGroup[],
  group: null as IGroup | null,
  authorities: [] as IGroupRole[],
  updating: false,
  updateSuccess: false
};

export type GroupState = typeof initialState;

export const slice = createSlice({
  name: 'group',
  initialState,
  reducers: {
    updateGroupsWithDevicesReducer: (state, action: PayloadAction<IDevice[]>) => {
      if (action.payload.length) {
        state.groups.forEach(group => {
          group.devices = []
          action.payload.forEach(device => {
            if (device.groupMembership.find(item => item.group_id === group.group_id)) {
              // @ts-ignore
              group.devices.push(device)
            }
          })
        })
      }
    },
    fetchGroupsStart: state => {
      state.loading = true;
      state.errorMessage = '';
    },
    fetchGroupsFailed: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.errorMessage = action.payload;
    },
    fetchGroupsSuccess: (state, action: PayloadAction<IGroup[]>) => {
      state.loading = false;
      state.groups = action.payload;
    },
    fetchAuthoritiesSuccess: (state, action: PayloadAction<IGroupRole[]>) => {
      state.loading = false;
      state.authorities = action.payload;
    },
    updateGroupStart: state => {
      state.updating = true;
      state.errorMessage = '';
      state.updateSuccess = false;
    },
    updateGroupFailed: (state, action: PayloadAction<string>) => {
      state.updating = false;
      state.updateSuccess = false;
      state.errorMessage = action.payload;
    },
    updateGroupSuccess: (state, action: PayloadAction<IGroup>) => {
      const index = findIndex(state.groups, { group_id: action.payload.group_id })
      if (index !== -1) {
        state.groups[index] = action.payload
      } else {
        state.groups.push(action.payload)
      }
      state.updating = false;
      state.updateSuccess = true;
      state.group = action.payload;
    },
    deleteGroupSuccess: state => {
      state.updateSuccess = true;
      state.updating = false;
      state.group = null;
    },
    resetGroup: state => {
      state.group = null;
    }
  }
});

export default slice.reducer;

//Actions
const {
  fetchGroupsStart,
  fetchGroupsFailed,
  fetchGroupsSuccess,
  fetchAuthoritiesSuccess,
  updateGroupStart,
  updateGroupFailed,
  updateGroupSuccess,
  deleteGroupSuccess, 
  updateGroupsWithDevicesReducer
} = slice.actions;

export const { resetGroup } = slice.actions;

const apiUrl = getEnvApiUrl();

export const updateGroupsWithDevices = (): AppThunk => (dispatch, getState) => {
  const state =getState()
  dispatch(updateGroupsWithDevicesReducer(state.devices.devices));
};
export const fetchGroups = (): AppThunk => async dispatch => {
  try {
    dispatch(fetchGroupsStart());
    const response: AxiosResponse<IGroup[]> = await axios.get(`${apiUrl}/groups/?withRelated=userGroupMemberships&ordering=group_name`);
    await dispatch(fetchGroupsSuccess(response.data));
    await dispatch(updateGroupsWithDevices())
  } catch (error) {
    const errorMsg = getRequestErrorMessage(error);
    dispatch(fetchGroupsFailed(errorMsg));
    dispatch(errorNotification(`${errorMsg}`));
  }
};


export const fetchAuthorities = (): AppThunk => async dispatch => {
  try {
    const response: AxiosResponse<IGroupACLResponse> = await axios.get(`${apiUrl}/group-acl-roles/`);
    dispatch(fetchAuthoritiesSuccess(response.data.results));
  } catch (error) {
    const errorMsg = getRequestErrorMessage(error);
    dispatch(errorNotification(`${errorMsg}`));
  }
};

export const  updateGroup =
  (group: any, fetch: boolean = false): AppThunk =>
  async dispatch => {
    try {
      dispatch(updateGroupStart());
      //@ts-ignore
      const device_ids = group.devices.map(item => item.device_id) ;
      delete group.devices
      delete group.user_role_in_group
      delete group.accessories
      const client_poi_id =  group?.client_poi_id?.trim() !== '' ? group.client_poi_id : null
      
      const response: AxiosResponse<IGroup> = await axios.patch(`${apiUrl}/groups/${group.group_id}/`, {
        ...group,
        device_ids,
        client_poi_id
      });

      dispatch(updateGroupSuccess(response.data));
      dispatch(updateDevicesFromGroup(response.data.group_id, response.data.devices));
    } catch (error) {
      const errorMsg = getRequestErrorMessage(error);
      dispatch(updateGroupFailed(errorMsg));
      dispatch(errorNotification(`${errorMsg}`));
    }
  };

export const createGroup =
  (group: any): AppThunk =>
  async dispatch => {
    try {
      dispatch(updateGroupStart());
      //@ts-ignore
      const device_ids = group.devices.map(item => item.device_id);
      group.device_ids = device_ids
      delete group.devices
      const response: AxiosResponse<IGroup> = await axios.post(`${apiUrl}/groups/`, group);
      const updatedGroup = response.data;
      dispatch(updateGroupSuccess(updatedGroup));
      dispatch(updateDevicesFromGroup(response.data.group_id, response.data.devices));
    } catch (error) {
      const errorMsg = getRequestErrorMessage(error);
      dispatch(updateGroupFailed(errorMsg));
      dispatch(errorNotification(`${errorMsg}`));
    }
  };

export const deleteGroup =
  (group: IGroup): AppThunk =>
  async dispatch => {
    try {
      dispatch(updateGroupStart());
      await axios.delete(`${apiUrl}/groups/${group.group_id}/`);
      dispatch(deleteGroupSuccess());
    } catch (error) {
      const errorMsg = getRequestErrorMessage(error);
      dispatch(updateGroupFailed(errorMsg));
      dispatch(errorNotification(`${errorMsg}`));
    }
  };
